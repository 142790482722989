<template>
  <div class="app-install-success">
    <img src="../../../assets/images/shoplazza/success.png" class="success" />
    <div class="congratulations">Congratulations!</div>
    <div class="tips">
      <div>Onerway is activated in your store. </div>
      <div style="margin-top: 20px;">Get support from Onerway team - merchant@pacypay.com.</div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('app/setBrandInfo', {});
  },
  mounted() {
    const url = this.$route.query.redirect;
    if (url) {
      // 5秒后跳转
      setTimeout(() => {
        window.location.href = window.decodeURIComponent(url);
      }, 3000);
    }
  },
};
</script>
<style lang="scss" scoped>
.app-install-success {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 130px;
  .success {
    width: 134px;
  }
  .congratulations {
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #212B36;
    line-height: 28px;
    margin-top: 28px;
  }
  .tips {
    font-size: 18px;
    color: #616D7A;
    line-height: 21px;
    margin-top: 20px;
    text-align: left;
  }
}
</style>
